<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>선정산 사업자 선택</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="user in userList"
                :key="user.username"
                @click="handleUserSelect(user.username)"
                :disabled="loading"
              >
                <v-list-item-icon>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ user.companyName }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-progress-circular
                    v-if="loading && selectedUser === user.username"
                    indeterminate
                    size="20"
                    color="primary"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MyfinbizLogin',
  data() {
    return {
      loading: false,
      selectedUser: '',
      userList: [
        { username: 'jason@funda.kr', companyName: '번개입금' },
        { username: 'fdi5100@hanmail.net', companyName: '정훈킹페이' },
        { username: 'hisc.master@gmail.com', companyName: '페이원' },
        { username: 'ohio@gayou.kr', companyName: '미리드림' },
        { username: 'kevin.lee@airnew.kr', companyName: '바로쏜다' },
        { username: 'support@local17.co.kr', companyName: '바로입금' },
        { username: 'jungle1644@daum.net', companyName: '정글페이' },
        { username: 'jhm8408@hanmail.net', companyName: '신신엠앤씨' },
      ]
    }
  },
  methods: {
    ...mapActions('myfinbiz/auth', ['loginMyfinbiz']),
    async handleUserSelect(username) {
      this.selectedUser = username
      this.loading = true
      try {
        await this.loginMyfinbiz(username)
        this.$router.push({ name: 'myfinbiz/customers' })
      } catch (error) {
        console.error('Login failed:', error)
        this.$store.dispatch('snackbar/showSnackbar', {
          text: '로그인에 실패했습니다. 다시 시도해주세요.',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
